import React from "react";
import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import HeaderTwo from "../common/header/HeaderTwo";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import TeamTwo from "../elements/team/TeamTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import GalleryOne from "../elements/gallery/GalleryOne";
import Qsomos from "../elements/Qsomos";
import BrandTwo from "../elements/brand/BrandTwo";
import BrandFour from "../elements/brand/BrandFour";
import FooterTwo from "../common/footer/FooterTwo";
import Separator from "../elements/separator/Separator";
import CalltoActionTwo from "../elements/calltoaction/CalltoActionTwo";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import SEO from "../common/SEO";

var BlogListData = BlogClassicData.slice(0, 3);
const BannerData = [
  {
    image: "/images/bg/prueba1.png",
    title: "Inscripciones",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
  },
  {
    image: "/images/bg/bg-image-18.jpg",
    title: "Fundación<br /> Miss Paraguay.",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
  },
];
const PopupData2 = [
  {
    id: "00",
    image: "./images/misses/1991.png",
  },
  {
    id: "01",
    image: "./images/misses/1992.png",
  },
  {
    id: "02",
    image: "./images/misses/1993.png",
  },
  {
    id: "03",
    image: "./images/misses/1994.png",
  },
  {
    id: "04",
    image: "./images/misses/1995.png",
  },
  {
    id: "05",
    image: "./images/misses/1996.png",
  },
  {
    id: "06",
    image: "./images/misses/1997.png",
  },
  {
    id: "07",
    image: "./images/misses/1998.png",
  },
  {
    id: "08",
    image: "./images/misses/1999.png",
  },
  {
    id: "09",
    image: "./images/misses/2000.png",
  },
  {
    id: "10",
    image: "./images/misses/2001.png",
  },
  {
    id: "11",
    image: "./images/misses/2004.png",
  },
  {
    id: "12",
    image: "./images/misses/2005.png",
  },
  {
    id: "13",
    image: "./images/misses/2009.png",
  },
  {
    id: "14",
    image: "./images/misses/2010.png",
  },
  {
    id: "15",
    image: "./images/misses/2011.png",
  },
  {
    id: "16",
    image: "./images/misses/2012.png",
  },
  {
    id: "17",
    image: "./images/misses/2013.png",
  },
  {
    id: "18",
    image: "./images/misses/2014.png",
  },
  {
    id: "19",
    image: "./images/misses/2015.png",
  },
  {
    id: "20",
    image: "./images/misses/2016.png",
  },
  {
    id: "21",
    image: "./images/misses/2017.png",
  },
  {
    id: "22",
    image: "./images/misses/2018.png",
  },
  {
    id: "23",
    image: "./images/misses/2019.png",
  },
];
const Inicio = () => {
  return (
    <>
      <SEO title="MISS PARAGUAY" />
      <HeaderTwo
        btnStyle="btn-small round btn-icon"
        HeaderSTyle="header-transparent"
      />
      {/* Start Slider Area  */}
      {/* <Slider
        className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow"
        {...BannerActivation}
      >
        {BannerData.map((data, index) => (
          <div key={index} className="single-slide">
            <div
              className="height-950 bg-overlay bg_image"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`,
              }}
            >
              <div className="container">
                <div className="row row--30 align-items-center">
                  <div className="col-lg-12">
                    <div className="inner text-center">
                  
 <h1 className="title theme-gradient display-two">Miss Paraguay</h1>
           
                      <p
                        className="description"
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      ></p>
                      <div className="button-group mt--30">
                        <a
                          className="btn-default btn-large round"
                          target="_blank"
                          href="/contacto"
                        >
                          Contactanos
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider> */}
      <div
        className="slider-area slider-style-2 variation-3 height-850 bg_image bg_image_fixed theme-shape"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/prueba1.png)`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="inner text-start">
                <h1 className="title text-center">
                  {" "}
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logo/logomisspy.png`}
                    style={{ width: "30rem" }}
                    alt="Miss Paraguay 2023"
                  />
                </h1>
                <p className="description">
                  Sé la representante oficial de la belleza y la cultura
                  paraguaya.
                  <br />
                  El mayor y único Certamen Nacional y Oficial de Belleza,
                  Cultura y Acción Social más importante del país MISS PARAGUAY
                  2023 abre inscripciones en búsqueda de las candidatas de los
                  17 departamentos y de Asunción para competir por la máxima
                  corona.
                </p>
                <div className="button-group mt--40 mt_sm--20">
                  <Link
                    className="btn-default btn-border"
                    to="/bases-condiciones.pdf"
                  >
                    Bases y condiciónes
                  </Link>
                  <a
                    className="btn-default btn-icon"
                    href=" https://docs.google.com/forms/d/e/1FAIpQLSe8Lh2UOg3TgUJ2SQjVB7dHeavUPTMZiSW-2siO6ynCY6yinA/viewform
"
                  >
                    {" "}
                    Inscribite aquí
                    <i className="icon">
                      <FiArrowRight />
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /> <br />
      {/* End Slider Area  */}
      <div className="blog-details-content pt--60 rn-section-gapBottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="row">
                <div className="col-lg-12 mb--20">
                  <h1
                    className="title theme-gradient display-two text-center"
                    id="nosotros"
                  >
                    Nosotros...
                  </h1>
                </div>
              </div>
              <div className="content">
                <p style={{ color: "#d1a664" }}>
                  Apuntamos desde esta nueva etapa empresarial, seguir los pasos
                  de nuestro fundador Guillermo Rolón; destacado representante
                  nacional en la promoción de la belleza y la cultura paraguaya,
                  que desde el año 1990 ha recorrido con la organización todo el país para la elección de las 18 Misses para
                  las diversas actividades sociales y culturales con la flamante
                  Miss Paraguay de cada año y actividades benéficas y solidarias
                  canalizadas por la Fundación Miss Paraguay. En las
                  trasmisiones televisivas de Miss Paraguay se supera punto de
                  índice de audiencia nacional entre otros programas y fue por
                  varios años consecutivo trending topic nacional y tendencia
                  mundial en redes sociales con el hashtag #misspy.
                </p>
              </div>
              <div className="about-area about-style-4 rn-section-gap2">
                <div className="inner">
                  <h4 className="title">Declarado:</h4>
                  <ul className="feature-list">
                    <li>
                      <div className="icon">
                        <FiCheck />
                      </div>
                      <div className="title-wrapper">
                        <h4 className="title" style={{ color: "#fee999" }}>
                          Interés Turístico Nacional.
                        </h4>
                        <p className="text" style={{ color: "#fff" }}>
                          Por el Gobierno Nacional a través de la Secretaría
                          Nacional de Turismo de Interés Turístico Nacional.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <FiCheck />
                      </div>
                      <div className="title-wrapper">
                        <h4 className="title" style={{ color: "#fee999" }}>
                        Interés Nacional y Cultural.
                        </h4>
                        <p className="text" style={{ color: "#fff" }}>
                          Por la Honorable Cámara de Diputados de la Nación
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <FiCheck />
                      </div>
                      <div className="title-wrapper">
                        <h4 className="title" style={{ color: "#fee999" }}>
                          {" "}
                          Interés Municipal.
                        </h4>
                        <p className="text" style={{ color: "#fff" }}>
                          Por la Intendencia Municipal y la Junta Municipal de
                          la ciudad de Asunción
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <Qsomos
                column="col-lg-6 col-md-6 col-12 mt--10"
                teamStyle="team-style-default style-two"
              />
            </div>
          </div>
        </div>
      </div>
      <br /> <br />
      {/* Start Elements Area  */}
      <div className="rwt-team-area rn-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="title theme-gradient display-two" id="directivos">
                Directivos
              </h1>
            </div>
          </div>
          <TeamTwo
            column="col-lg-4 col-md-6 col-12 mt--10"
            teamStyle="team-style-default style-two"
          />
        </div>
      </div>
      <br /> <br />
      {/* End Elements Area  */}
      <div className="rwt-gallery-area rn-section-gapBottom">
        <div className="container">
          <div className="col-lg-12 mb--20">
            <h1
              className="title theme-gradient display-two text-center"
              id="eventos"
            >
              Próximos eventos
            </h1>
          </div>
          <div className="row row--15 innercenter">
            {BlogListData.map((item) => (
              <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                <BlogList StyleVar="box-card-style-default" data={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <br /> <br />
      {/* Start Elements Area  */}
      <div className="rwt-gallery-area rn-section-gapBottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb--20">
              <h1
                className="title theme-gradient display-two text-center"
                id="galeria"
              >
                Galería de Misses
              </h1>
            </div>
          </div>
          <div className="row mt_dec--30 row--15">
            {PopupData2.map((item) => (
              <div
                className="col-lg-3 col-md-6 col-sm-4 col-12 mt--30"
                key={item.id}
              >
                <GalleryOne galleryItem={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Elements Area  */}
      <br /> <br />
      <div className="row">
        <div className="col-lg-12 mb--20">
          <h1
            className="title theme-gradient display-two text-center"
            id="auspiciantes"
          >
            Auspiciantes
          </h1>
        </div>
        <div className="col-lg-12 mt--40">
          <BrandFour brandStyle="brand-style-2 variation-2" />
        </div>
      </div>
      <br />
      <br />
      {/* Start Elements Area  */}
      <div className="rwt-callto-action-area rn-section-gapBottom">
        <div className="wrapper">
          <CalltoActionTwo />
        </div>
      </div>
      {/* End Elements Area  */}
      <FooterTwo />
    </>
  );
};
export default Inicio;
