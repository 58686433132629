import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'g1',
        name: 'Guillermo Rolón',
        designation: '(1953-2022)',
        location: 'CO Miego, AD, USA', 
        description: 'Yes, I am a product designer. I have a passion for product design.',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: 'https://www.facebook.com/profile.php?id=100006819164751'
            },
            {
                icon: <FiInstagram />,
                url: 'https://www.instagram.com/guillermo031153/'
            }
        ]
       
    },
    {
        image: 'gra2',
        name: 'Graciela Venialgo',
        designation: 'Presidente',
        location: 'Bangladesh', 
        description: 'Yes, I am a product designer. I have a passion for product design.',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: 'https://www.facebook.com/graciela.venialgo.545'
            },
            {
                icon: <FiInstagram />,
                url: 'https://www.instagram.com/graciela28venialgo/'
            },
        ]
      
    }
]

const TeamTwo = ({column , teamStyle}) => {
    return (
        <div className="row row--15 innercenter">
            {teamData.map((data, index) => (
                <div className={`${column} `} key={index}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`rn-team ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/team/${data.image}.png`} alt="certamen de belleza" />
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient2" style={{color:"white"}}>{data.designation}</h6>
                                    {/* <span className="team-form">
                                        <img src="./images/team/location.svg" alt="Corporate React Template" />
                                        <span className="location" style={{color:"white"}}>{data.location}</span>
                                    </span> */}
                                    {/* <p className="description" style={{color:"white"}}>{data.description}</p> */}

                                    <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index} ><a href={`${social.url}`} style={{color:"#003440"}}>{social.icon}</a></li>
                                        )}
                                    </ul>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TeamTwo;
